import React, { useCallback, useContext, useMemo } from "react";
import { PlayerCardContext } from "src/services/PlayerCardService";
import { Player } from "src/models";
import AnalyticsService from "src/services/AnalyticsService";
import GAService from "src/services/GAService";
import { SLUG_SPORTS_MAP, SPORTS_MAP } from "src/constants";
import { getStringQueryParam } from "src/services/UrlService";
import { useRouter } from "next/router";

type PlayerCardButtonProps = {
  children: React.ReactNode;
  className?: string;
  player: Player;
  league?: string;
};

export const PlayerCardButton = ({ className = "", children, player, league }: PlayerCardButtonProps) => {
  const ctx = useContext(PlayerCardContext);
  const { query } = useRouter();

  const sportAlias = league ?? getStringQueryParam(query?.sport)?.toUpperCase();

  const sport = SPORTS_MAP[SLUG_SPORTS_MAP[sportAlias]];

  const hasPlayerCard = sport?.hasPlayerCards;
  const onClick = useCallback(() => {
    if (hasPlayerCard) {
      GAService.sendEvent({
        hitType: "event",
        eventCategory: "Player Card Open",
        eventAction: window.location.href,
        eventLabel: player.fullName,
      });
      AnalyticsService.track("Player Card Open", {
        screen: "PlayerCard",
        tab: "Summary",
        sport: sport.name,
        playerId: player.id,
        player: player.fullName,
      });

      return ctx.openModal(player, sport.name);
    }
    return;
  }, [ctx.openModal, player.id, hasPlayerCard]);
  const style = useMemo(() => ({ cursor: hasPlayerCard ? "pointer" : "default" }), [hasPlayerCard]);
  return (
    <div className={`${className} player-card-open`} style={style} onClick={onClick}>
      {children}
    </div>
  );
};
