import React from "react";
import moment from "moment-timezone";
import _ from "lodash";
import { DEFAULT_BOOK_ID } from "@rotoql/common-services";
import { generateEventCardDisplayProps, selectGames } from "src/services/EventService";
import { getDefaultAfterDateRange, getDefaultBeforeDateRange } from "src/services/DateService";
import { SPORT_INDEX_EVENTS } from "src/gql/queries";
import { DEFAULT_SPORT, WebSportItem } from "src/constants";

import LeagueHeading from "../LeagueHeading";
import GameCard from "../GameCard";
import styles from "./styles.scss";
import { useQuery, useReactiveVar } from "@apollo/client";
import { currentSport } from "src/services/ApolloService";
import { ScheduleItem } from "src/services/ScheduleService";

export const useGamesSportIndexData = (schedule: ScheduleItem[]) => {
  const league = useReactiveVar(currentSport) ?? DEFAULT_SPORT;
  const sportIndexBefore = getDefaultBeforeDateRange(league.lookupKey, schedule);
  const sportIndexAfter = getDefaultAfterDateRange(league.lookupKey, schedule);
  const gamesResponse = useQuery(SPORT_INDEX_EVENTS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      league: league.key,
      after: sportIndexAfter,
      before: sportIndexBefore,
      bookId: DEFAULT_BOOK_ID, // Consensus
    },
  });
  return gamesResponse.data?.events ?? [];
};

export const SportGamesConnector = (props: { games: any[] }) => {
  const league = useReactiveVar(currentSport) ?? DEFAULT_SPORT;
  const now = moment.utc();

  let events: any[] = props.games ?? [];
  events = _.cloneDeep(events);
  events = selectGames(now, events, 6);
  events = events.map((event: any) => generateEventCardDisplayProps(event, league.lookupKey, DEFAULT_BOOK_ID));

  return <SportGames league={league} events={events} />;
};

export default SportGamesConnector;

export const SportGames = ({ league, events }: { league: WebSportItem; events: any[] }) => {
  return (
    <React.Fragment>
      <LeagueHeading sportKey={events.length ? league.url : undefined} />
      {events.length ? (
        <div className="games-container">
          {events.map((event: any) => {
            return event ? (
              <div className="game" key={event.id}>
                <GameCard game={event} />
              </div>
            ) : null;
          })}
        </div>
      ) : null}
      <style jsx>{styles}</style>
    </React.Fragment>
  );
};
