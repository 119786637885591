import React from "react";
import Link from "next/link";

import { WEB_SPORTS_MAP, DEFAULT_SPORT_KEY } from "../../constants";
import styles from "./styles.scss";

interface LeagueHeadingProps {
  sportKey?: string;
}

const LeagueHeading = (props: LeagueHeadingProps) => {
  const currentSportObj = WEB_SPORTS_MAP[props.sportKey || DEFAULT_SPORT_KEY];
  let gamesSyntax;
  if (currentSportObj.isTennis) {
    gamesSyntax = "Matches";
  } else {
    gamesSyntax = "Games";
  }
  return (
    <div className="league-heading">
      <h2 className="league-title">
        {props.sportKey ? `${currentSportObj.name} ${gamesSyntax}` : `No ${gamesSyntax}`}
      </h2>
      {props.sportKey ? (
        <Link href={`/${currentSportObj.url}/odds`} legacyBehavior>
          <a className="league-show-all">Show All {gamesSyntax}</a>
        </Link>
      ) : null}
      <style jsx global>
        {styles}
      </style>
    </div>
  );
};

export default LeagueHeading;
