import { useReactiveVar } from "@apollo/client";
import Link from "next/link";
import { RichText } from "prismic-reactjs";
import React, { useRef } from "react";
import { currentSport } from "src/services/ApolloService";
import { imgixResize } from "src/services/PrismicService";
import { getArticleAsUrl } from "src/services/UrlService";
import { useContainerQuery } from "src/services/UtilService";
import styles from "./styles.scss";

const ArticleGrid = ({ articles = [] }: { articles: any[] }) => {
  const container = useRef(null);

  const size =
    useContainerQuery(container, {
      small: 600,
      medium: 900,
      large: Infinity,
    }) || "large";

  const itemsDisplayed = {
    small: 9,
    medium: 8,
    large: 9,
  }[size];

  return (
    <div ref={container} className="article-grid">
      {articles.slice(0, itemsDisplayed).map((article: any, idx: number) => (
        <ArticleCard key={idx} article={article} />
      ))}
      <style jsx>{styles}</style>
    </div>
  );
};

const ArticleCard = ({ article }: { article: any }) => {
  if (!article) {
    return null;
  }

  const { article_image, title, league } = article;

  if (!article_image || !title || !league) {
    return null;
  }

  const imageUrl = imgixResize(article_image?.url, {
    width: 280,
    height: 200,
  });

  return (
    <Link href={getArticleAsUrl(article)} legacyBehavior>
      <a className="article-card">
        <img className="image lazyload" data-src={imageUrl} alt="Default Article Image" />
        <div className="info">
          <div className="title">{RichText.asText(title)}</div>
          <div className="metastamp">
            {article.fromNow}
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {league.toUpperCase()}
          </div>
        </div>
      </a>
    </Link>
  );
};

export const LatestArticles = ({ title, articles }: { title?: string; articles: any[] }) => {
  const league = useReactiveVar(currentSport);

  return (
    <div className="latest-articles">
      <div className="latest-articles-title">
        <h2>{title || "Latest Articles"}</h2>
        <Link href={league ? `/${league.url}/news` : "/news"} legacyBehavior>
          <a className="see-more-articles">See All</a>
        </Link>
      </div>
      <div style={{ height: 12 }} />
      <ArticleGrid articles={articles} />
      <style jsx>{styles}</style>
    </div>
  );
};

export default ArticleGrid;
