import React, { useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { POPULAR_PLAYERS_PROPS_QUERY } from "src/gql/queries";
import styles from "./styles.scss";
import { useRouter } from "next/router";
import { DEFAULT_TIMEZONE, SLUG_SPORTS_MAP, WEB_SPORTS_LIST, WEB_SPORTS_MAP } from "src/constants";
import { useCarousel } from "src/components/Carousel";
import AnalyticsService from "src/services/AnalyticsService";
import FocusedTextLink from "../FocusedTextLink";
import { useGameProgressAndChannel } from "src/services/EventService";
import Icon from "@mdi/react";
import { mdiLock } from "@mdi/js";
import PlayerImage from "../Player/PlayerImage";
import { PlayerProps } from "./types";
import { SubscriptionContext } from "src/services/SubscriptionService";
import moment from "moment";
import { PlayerCardButton } from "../PlayerCard/PlayerCardButton";
import { getStringQueryParam } from "src/services/UrlService";

// NOTE: key is DB league name. text is display name
const getLeagueFromName = (name: string) => WEB_SPORTS_LIST.find((x) => x.key === name);

const RenderPlayerProps = ({ league }: { league: string }) => {
  const sport = WEB_SPORTS_MAP[SLUG_SPORTS_MAP[league]];
  const { query } = useRouter();

  const sportQueryString = getStringQueryParam(query?.sport);

  const before = useMemo(() => {
    // Determine the before date for the events query
    const date = moment().tz(DEFAULT_TIMEZONE).endOf("day").utc();
    if (sport?.hasConfiguredSchedule) {
      if (sport.key === "NFL") {
        return date.add(12, "days");
      }
      return date.add(6, "days");
    }
    return date;
  }, [sport]);

  const { data, loading } = useQuery(POPULAR_PLAYERS_PROPS_QUERY, {
    ssr: false,
    variables: { league, before, after: moment().tz(DEFAULT_TIMEZONE).startOf("day").utc() },
    pollInterval: 1000 * 60 * 10, // 10min
    fetchPolicy: "no-cache",
  });

  const playerProps: PlayerProps[] = data?.playerPropsTonight ?? [];

  const playerPropsComponents = loading
    ? [1, 2, 3, 4, 5, 6].map((i) => <PlayerPropPlaceholder key={i} />)
    : playerProps.map((playerProp) => <PlayerProp playerProp={playerProp} />);

  const { Carousel, CarouselNav } = useCarousel(playerPropsComponents, {
    contentHash: String(loading) + playerProps.map((event: any) => event.id).join(""),
  });

  if (!playerProps.length || (sportQueryString && sportQueryString.toUpperCase() !== league)) {
    return null;
  }

  return (
    <div>
      <div className="header">
        <span className="header-title">
          <h2>Top {sport.name} Players</h2>
        </span>
        <CarouselNav />
      </div>

      <Carousel />
    </div>
  );
};

const PlayerPropsTonight = () => {
  const sports = WEB_SPORTS_LIST.filter((sport) => sport.hasPlayerProps);
  return (
    <div className="popular-player-props">
      {sports.map((league) => (
        <RenderPlayerProps key={league.key} league={league.key} />
      ))}

      <style jsx>{styles}</style>
    </div>
  );
};

export default PlayerPropsTonight;

const PlayerPropPlaceholder = () => {
  const height = 16;

  return (
    <div className="card" style={{ height: 185 }}>
      <div className="player">
        <div className="player-image placeholder" style={{ width: 50, height: 40 }} />
        <div className="player-detail">
          <div className="player-name placeholder" style={{ width: 45, height, marginBottom: 8 }} />
          <div className="team">
            <div className="team-name-position placeholder" style={{ width: 159, height }} />
            <div className="book-value placeholder" style={{ width: 60, height }} />
          </div>
        </div>
      </div>

      <div className="game">
        <div className="game-name placeholder" style={{ width: 200, height, marginBottom: 8 }} />
        <p className="time placeholder" style={{ width: 150, height }} />
        <div className="cta-button placeholder" style={{ height: 40 }} />
      </div>
    </div>
  );
};

// Player Prop Card
const PlayerProp = ({ playerProp }: { playerProp: PlayerProps }) => {
  const router = useRouter();
  const { hasSubscriptionAccess } = useContext(SubscriptionContext);

  const { player, event, team, bookValue, propAbbreviation } = playerProp;
  const { league, slugId, homeTeam, awayTeam, freeGame } = event;

  const hasAccess = hasSubscriptionAccess(league.name) || freeGame;

  const analyticObject = {
    platform: "web",
    page: window.location.href,
    component: "PlayerPropsTonight",
    league: league.name,
  };

  const onSeeFullAnalysis = () => {
    const sportSlug = getLeagueFromName(league.name)?.url;

    router.push(`/${sportSlug}/game-predictions/${slugId}#props`, undefined, {
      scroll: true,
    });

    AnalyticsService.track("Top Players - Full Analysis Click", analyticObject);
  };

  const onPlayerCardClick = () => AnalyticsService.track("Top Players - Player Card Click", analyticObject);
  const progressAndChannel = useGameProgressAndChannel(event);

  return (
    <div className="card">
      <div className="player">
        <PlayerImage url={player.imageUrl} size={40} containerClassName="player-image" />

        <div className="player-detail">
          <PlayerCardButton player={player} league={league.name}>
            <button className="player-name" onClick={onPlayerCardClick}>
              {player.fullName}
            </button>
          </PlayerCardButton>

          <div className="team">
            <span className="team-name-position">
              {team.lastName} {player.position}
            </span>
            <span className="book-value">
              O/U {bookValue} {propAbbreviation}
            </span>
          </div>
        </div>
      </div>

      <div className="game">
        <span className="game-name">
          {awayTeam.lastName} vs {homeTeam.lastName}
        </span>
        <p className="time">{progressAndChannel}</p>

        <FocusedTextLink onClick={onSeeFullAnalysis} className="cta-button">
          {!hasAccess ? <Icon path={mdiLock} size="15px" color="#fff" /> : null} See Full Analysis
        </FocusedTextLink>

        <PlayerCardButton player={player} league={league.name}>
          <button className="cta-button open-player-card" onClick={onPlayerCardClick}>
            View Matchup and Trends
          </button>
        </PlayerCardButton>
      </div>
    </div>
  );
};
