import React from "react";
import RecommendedBets from "src/components/RecommendedBets";
import PopularEvents from "src/components/PopularEvents";
import styles from "./styles.scss";
import PlayerPropsTonight from "../PlayerPropsTonight";

const FeaturedTrends = ({ isHomepage = false }: { isHomepage?: boolean }) => {
  return (
    <section aria-label="Featured Trends" className="featured-trends">
      <RecommendedBets dark="true" isHomepage={isHomepage} />
      <PopularEvents dark="true" isHomepage={isHomepage} />
      <PlayerPropsTonight />
      <style jsx>{styles}</style>
    </section>
  );
};

export default FeaturedTrends;
